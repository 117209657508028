import { useRef, useState, useEffect, useCallback } from 'react'
import { clone, debounce, sleep } from '../../utils/helpers'
import { request } from '../../utils/api'
import { syncSlideItemsWithOriginState } from '../../utils/videos'
import { UNSAVED_VIDEO_STATUSES } from './constants'
import { isTesting } from '../../utils/config'
import { useElaiNotification } from '../../hooks/useElaiNotification'

const isDebug = process.env.NODE_ENV === 'development'

const MAX_HISTORY_LENGTH = 50

const ignoredChanges = ['duration', 'status', 'voiceType', 'internal', 'speech', 'approxDuration']
const readyStatuses = ['ready', 'renderingSlide']

export const useUpdateSave = ({
  video,
  setVideo,
  videoRef,
  playerRef,
  updatePlayerState,
  user,
  checkAdminLoginedAsUser,
  activeSlide,
  updateActiveSlide,
  canvasRef,
  canvasRegistry,
  setCanvasActiveObject,
  setIsSpeechUndo,
  onSaveSuccess,
  onSaveError,
  isStoryCreation = false,
  checkSlideInEditingMode = () => true,
}) => {
  const notification = useElaiNotification()
  const [changesHistory, setChangesHistory] = useState([])
  const [videoSavingStatus, setVideoSavingStatus] = useState('saved') // saving, unsaved
  const autosaveTimer = useRef()
  const currentVideoState = useRef()
  const savingPromiseRef = useRef(null)
  const undoTimeoutRef = useRef()
  const isUndoDebouncing = useRef(false)

  /**
   * For syncing actual/original copy before any update
   * This copy will be saved to history
   */
  useEffect(() => {
    if (video && !currentVideoState.current) {
      const clonedVideo = clone(video)
      currentVideoState.current = clonedVideo
    }
  }, [video])

  useEffect(() => {
    return () => clearAutoSave()
  }, [])

  const restoreLastState = (reason) => {
    let message = "Your slide is currently playing. Changes won't be saved."

    if (reason === 'changing_video_on_validation') message = "Your video is still validating. Changes won't be saved."
    else if (reason === 'reviewer_role') message = "Your role doesn't allow editing videos."
    else if (reason === 'story_slides_are_generating') message = "Slide generation in progress. Changes won't be saved."

    notification.error({
      key: 'changing_not_allowed',
      message,
    })
    return setVideo(clone(currentVideoState.current))
  }

  const syncHistoryWithGlobalChanges = (history, changes) => {
    const rootChanges = ['status', 'version', '__v', 'data', 'template']
    history.forEach((h) => {
      const { video } = h
      for (const key of rootChanges) {
        if (changes[key]) video[key] = changes[key]
      }
      if (changes.slides) {
        video.slides.forEach((s, i) => {
          if (changes.slides[i]) {
            s.status = changes.slides[i].status
          }
        })
      }
    })
    return [...history]
  }

  const updateVideo = (
    changes,
    {
      ignoreHistory,
      skipAutoSave,
      replaceState,
      initialRequest,
      updatedSlideId,
      onUndo,
      updateActiveCanvas,
      allowThumbnailsUpdate,
      triggerPartialSave,
    } = {},
  ) => {
    if (isDebug)
      console.log('UPDATE VIDEO', changes, {
        ignoreHistory,
        skipAutoSave,
        replaceState,
        initialRequest,
        updatedSlideId,
        onUndo,
        updateActiveCanvas,
        allowThumbnailsUpdate,
        triggerPartialSave,
      })
    const time = performance.now()
    /**
     * For make sure we get the last correct state
     */
    const video = videoRef.current
    /**
     * for case when need to access video instance like:
     * updateVideo((video) => ({ slides: processSlides(video.slides) }))
     */
    if (typeof changes === 'function') {
      changes = changes(video)
    }
    /**
     * replace state with actual video data after fetching
     */
    if (replaceState) {
      currentVideoState.current = null

      if (initialRequest) {
        setChangesHistory((ch) => {
          if (ch.length) return syncHistoryWithGlobalChanges(ch, changes)

          ch = [...ch, { video: changes, updatedSlideId }]
          if (isDebug) console.log(`History updated init: ${ch.length}`)
          return ch
        })
      } else if (!ignoreHistory) {
        setChangesHistory((ch) => syncHistoryWithGlobalChanges(ch, changes))
      }

      return setVideo(changes)
    }

    // drop changes if user is reviewer
    if (user.accountRole === 'reviewer' && !changes.comments) return restoreLastState('reviewer_role')
    // drop changes in validating status
    if (video?.status === 'validating' && !Object.keys(changes).every((p) => p === 'status') && !ignoreHistory)
      return restoreLastState('changing_video_on_validation')
    // drop any changes in story slide generation mode
    if (isStoryCreation && !checkSlideInEditingMode() && !ignoreHistory)
      return restoreLastState('story_slides_are_generating')

    if (ignoreHistory) {
      setChangesHistory((ch) => syncHistoryWithGlobalChanges(ch, changes))
    } else {
      const newChange = {
        video: currentVideoState.current || clone(video),
        activeObject: canvasRef?.current?.getActiveObject(),
        updatedSlideId,
        onUndo,
      }
      setChangesHistory((ch) => {
        if (ch.length >= MAX_HISTORY_LENGTH) {
          if (isDebug) console.log(`History limit reached, dropping first item`)
          ch.shift()
        }
        ch = [...ch, newChange]
        if (isDebug) console.log(`History updated: ${ch.length}`)
        return ch
      })
      if (!skipAutoSave) initAutoSave()
    }
    /**
     *  Apply current changes as original instance
     */
    currentVideoState.current = null
    setVideo((v) => Object.assign({}, v, changes))

    if (allowThumbnailsUpdate && !updatedSlideId && 'slides' in changes) {
      updatePlayerState({ canvasReady: false, canvasesLocked: true })
      canvasRegistry?.updateCanvases(changes.slides).then(() => {
        updatePlayerState({ canvasReady: true, canvasesLocked: false })
      })
    }

    if (updateActiveCanvas) {
      const canvasActiveObject = canvasRef?.current?.getActiveObject()

      canvasRef?.current?.updateCanvasFromState(video.slides[activeSlide].canvas, canvasActiveObject)
    }

    /**
     * it's using for partial save, when we need to update only some fields
     * and prevent reseting video status to draft
     */
    if (triggerPartialSave && !autosaveTimer.current) savePartialVideoChanges(changes)

    if (isTesting) localStorage.setItem('__videoUpdated', performance.now() - time)
  }

  const updateCanvasForSlide = (slide, activeObject, canvas) => {
    // as we update state we should also update physical canvas to display renews
    return canvasRef.current?.updateCanvasFromState(
      canvas || slide.canvas,
      activeObject || canvasRef.current?.getActiveObject(),
    )
  }

  const updateSlide = useCallback(
    (data, { ignoreHistory, slideIndex } = {}) => {
      const video = videoRef.current
      const currentSlide = video.slides[slideIndex || activeSlide]

      ignoreHistory = ignoreHistory || Object.keys(data).every((p) => ignoredChanges.includes(p))

      // drop changes if user is reviewer
      if (user.accountRole === 'reviewer') {
        restoreLastState('reviewer_role')
        return updateCanvasForSlide(currentSlide)
      }
      // drop changes in validating status
      if (video.status === 'validating') {
        restoreLastState('changing_video_on_validation')
        return updateCanvasForSlide(currentSlide)
      }
      // drop any changes in story slide generation mode
      if (!checkSlideInEditingMode() && !ignoreHistory) {
        restoreLastState()
        return updateCanvasForSlide(currentSlide)
      }

      if (isDebug) {
        console.log('UPDATE SLIDE', data, { slideIndex, activeSlide })
      }

      if (!currentSlide) {
        if (isDebug) {
          console.log('No slide found', { slideIndex, activeSlide, slidesCount: video.slides.length })
        }
        return
      }

      if (!data.status && readyStatuses.includes(currentSlide?.status)) {
        data.status = 'avatarReady'
      }

      if (!ignoreHistory && currentSlide.newlyAdded) {
        currentSlide.newlyAdded = false
      }

      const canvasUpdateRequested = data.updateCanvas
      delete data.updateCanvas

      Object.assign(currentSlide, data)
      updateVideo({ slides: video.slides }, { ignoreHistory, updatedSlideId: currentSlide.id })

      if (!canvasUpdateRequested) return

      return updateCanvasForSlide(currentSlide)
    },
    [videoRef.current, activeSlide],
  )

  const _undoLastChanges = async (skipCount = 0) => {
    if (!isStoryCreation && !playerRef.current?.canvasReady) {
      notification.warning({
        message: 'Current canvas are still restoring. Please wait until it will be ready.',
        duration: 3,
        key: 'undo-changes',
      })
      return
    }
    if (isDebug) console.log(`History undo: ${changesHistory.length}, skipCount: ${skipCount}`)
    if (changesHistory.length <= 1) return
    setIsSpeechUndo?.(true)

    const updatedHistory = skipCount
      ? changesHistory.slice(0, Math.max(2, changesHistory.length - skipCount))
      : changesHistory

    const { video: restoredVideo, activeObject, updatedSlideId, onUndo } = updatedHistory.pop()
    setChangesHistory(updatedHistory)

    if (!restoredVideo) {
      if (isDebug) console.warn(`History contains empty video state`)
      return
    }

    /**
     * Replace current video state by restored from history
     */
    const updatedSlideIndex =
      updatedSlideId !== undefined ? restoredVideo.slides.findIndex((s) => s.id === updatedSlideId) : undefined
    if (updatedSlideIndex > -1) {
      const originSlide = videoRef.current.slides[updatedSlideIndex]
      if (originSlide.status === 'ready') {
        const updatedSlide = restoredVideo.slides[updatedSlideIndex]
        updatedSlide.status = originSlide.speech === updatedSlide.speech ? 'avatarReady' : 'edited'
      }
    }
    updateVideo(restoredVideo, { replaceState: true, ignoreHistory: true })
    initAutoSave()

    video = restoredVideo
    if (isStoryCreation) await canvasRegistry.updateCanvasesInStoryMode(video.slides)
    if (!updateActiveSlide) return

    if (activeSlide >= video.slides.length) updateActiveSlide(0)

    if (updatedSlideIndex !== undefined) {
      if (updatedSlideIndex < 0) return

      const { slides } = video
      updateActiveSlide(updatedSlideIndex)
      undoTimeoutRef.current = setTimeout(() => {
        updateCanvasForSlide(slides[updatedSlideIndex], activeObject)
        undoTimeoutRef.current = null
      }, 200)
    } else {
      undoTimeoutRef.current = setTimeout(async () => {
        const activeObjectUpdated = await canvasRegistry.updateCanvases(videoRef.current.slides, {
          activeObject: activeObject || true,
        })
        if (activeObjectUpdated) setCanvasActiveObject(activeObjectUpdated)
        undoTimeoutRef.current = null
      }, 200)
    }
    if (onUndo) setTimeout(onUndo, 200)
  }

  const undoLastChangesDebounced = useCallback(
    debounce(
      (...args) => {
        _undoLastChanges(args.pop() - 1)
        isUndoDebouncing.current = false
      },
      300,
      true,
    ),
    [changesHistory],
  )

  const undoLastChanges = () => {
    // additional check for case when some sync actions are still in progress
    if (changesHistory.length <= 1) return

    // first undo in a row, probably without debounce, so just call it directly
    if (!undoTimeoutRef.current && !isUndoDebouncing.current) return _undoLastChanges()

    // otherwise we should debounce undo and cancel initial timeout to prevent double+ undo
    if (!isUndoDebouncing.current) clearTimeout(undoTimeoutRef.current)
    undoLastChangesDebounced()

    isUndoDebouncing.current = true
    undoTimeoutRef.current = null
  }

  const syncVideoWithBackend = (localVideo, fetchedVideo) => {
    if (!localVideo || !fetchedVideo) return

    const update = {}
    const fieldsForUpdate = ['status', 'version', '__v']
    if (localVideo) {
      for (const field of fieldsForUpdate) {
        if (localVideo[field] !== fetchedVideo[field]) update[field] = fetchedVideo[field]
      }
    }
    if (Object.keys(update).length) updateVideo(update, { ignoreHistory: true })
  }

  const initAutoSave = () => {
    setVideoSavingStatus('unsaved')

    if (UNSAVED_VIDEO_STATUSES.includes(video?.status)) {
      return console.log('Autosave is disabled for rendering videos')
    }

    if (
      (user.isAdmin && video?.accountId !== undefined && user.account.id !== video?.accountId) ||
      checkAdminLoginedAsUser()
    )
      return console.log('Autosave is disabled for editing not own videos')

    // save each x seconds, even if autosaveTimer is already launched
    if (!autosaveTimer.current) {
      autosaveTimer.current = setTimeout(() => {
        saveVideo().catch(console.error)
      }, 8000)
    }
  }

  const clearAutoSave = () => {
    if (autosaveTimer.current) {
      clearTimeout(autosaveTimer.current)
      autosaveTimer.current = null
    }
  }

  const savePartialVideoChanges = async (changes) => {
    // if whole video is already saving we should wait for completion
    if (savingPromiseRef.current) await savingPromiseRef.current

    const res = await request({ method: 'patch', url: `/videos/${video._id}`, data: changes })
    syncVideoWithBackend(video, res)
  }

  const saveVideo = async ({ ignoreVideoStatus } = {}) => {
    clearAutoSave()

    if (!ignoreVideoStatus && UNSAVED_VIDEO_STATUSES.includes(videoRef.current?.status)) return

    // if video is currently saving we ignore duplicated requests, but wait for completion of the first one
    if (savingPromiseRef.current) return await savingPromiseRef.current

    // videoRef should have the most updated version even in async handling, so we use it, not state
    const video = videoRef.current
    if (!video?.slides?.length) return console.log('No slides to save')

    setVideoSavingStatus('saving')
    let resolveSave
    savingPromiseRef.current = new Promise((resolve) => {
      resolveSave = resolve
    })

    if (isDebug) console.log('SAVING VIDEO...', { ignoreVideoStatus })

    const res = await request({
      method: 'patch',
      url: `/videos/${video._id}`,
      data: syncSlideItemsWithOriginState(video),
      onBeforeHandleError: (_, status) => {
        if (status === 409) {
          // reload page to get the most updated version of video
          setTimeout(async () => {
            // hack to prevent showing confirm on beforeunload
            setVideoSavingStatus('saved')
            // wait while react will update state
            await sleep(100)
            window.location.reload()
          }, 7000)
        }
      },
    })

    if (res) {
      syncVideoWithBackend(video, res)
      setVideoSavingStatus((prevStatus) => (prevStatus === 'saving' ? 'saved' : prevStatus))
      if (video.template) updateVideo({ template: { ...video.template, enabled: false } }, { ignoreHistory: true })
      onSaveSuccess?.(res)
    } else {
      setVideoSavingStatus((prevStatus) => (prevStatus === 'saving' ? 'unsaved' : prevStatus))
      clearAutoSave()
      onSaveError?.()
    }
    resolveSave()
    savingPromiseRef.current = null
    return res
  }

  const ensureVideoSaved = async () => {
    if (videoSavingStatus === 'saved' && !savingPromiseRef.current) return
    if (videoSavingStatus === 'saving' && savingPromiseRef.current) await savingPromiseRef.current
    else await saveVideo()
  }

  return {
    changesHistory,
    updateVideo,
    updateSlide,
    undoLastChanges,
    saveVideo,
    ensureVideoSaved,
    videoSavingStatus,
    setVideoSavingStatus,
    savingPromiseRef,
  }
}
