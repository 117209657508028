import Scrollbars from 'react-custom-scrollbars'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Upload, Button, Select, Checkbox, Tooltip } from 'antd'

import { useStore } from '../../../../store'
import PptxFontsPromptModal from './pptxFontsPromptModal'
import { requestUploadPptx } from '../../../../utils/videoCreation/videoCreation'
import { useElaiNotification } from '../../../../hooks/useElaiNotification'
import Icon from '../../../Icon'
import './createVideoFromPresentation.less'

const { Dragger } = Upload

const style = {
  createButton: { marginBottom: 30 },
}

const allowedMimeTypes = [
  'application/pdf',
  'image/svg+xml',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  // 'application/msword',
]

const allowedExtensions = ['pptx', 'ppt', 'pdf']

export const CreateVideoFromPresentation = (props) => {
  const { pdfFile, setPdfFile, setIsVideoCreating, isVideoCreating, setIsNewVideoModalOpen } = props
  const notification = useElaiNotification()

  const navigate = useNavigate()
  const { folderId } = useParams()
  const [showFontPrompt, setShowFontPrompt] = useState(false)
  const [pptxCreationResult, setPptxCreationResult] = useState(null)
  const [selectedTemplate, setSelectedTemplate] = useState(localStorage.getItem('pptxCreationTemplatedId'))
  const [staticMode, setStaticMode] = useState(null)
  const [privateTemplates, setPrivateTemplates] = useState([{ name: 'Create new template...', _id: 'new' }])

  const { templates } = useStore((stores) => stores.videosStore)

  const closeVideoModal = () => {
    setIsVideoCreating(false)
    setIsNewVideoModalOpen(false)
  }

  const handleErrors = (error) => {
    if (error.response.data?.context?.missedFonts) {
      setPptxCreationResult(error.response.data.context)
      setShowFontPrompt(true)
      return
    }
    if (error.response?.data?.message) notification.error({ message: error.response?.data?.message, duration: 0 })
    else if (error.message) notification.error({ message: error.message, duration: 0 })
    setIsVideoCreating(false)
  }

  const createVideoFromPresentation = async (mode) => {
    const fileExt = pdfFile.name.split('.').pop()
    setIsVideoCreating(true)
    setShowFontPrompt(false)

    // if (fileExt.toLowerCase() === 'pdf') convertImagesFromPdf(pdfFile, setConvertedImages, setIsVideoCreating)
    if (allowedExtensions.includes(fileExt.toLowerCase()) || mode === 'ignoreFonts') {
      const result = await requestUploadPptx({
        mode: staticMode ?? mode,
        folderId,
        file: pdfFile,
        urlPath: mode === 'ignoreFonts' ? pptxCreationResult?.urlPath : undefined,
        templateId: localStorage.getItem('pptxCreationTemplateId') ?? selectedTemplate,
        customErrorHandler: handleErrors,
      })
      if (result?.status === 206) {
        notification.success({
          message:
            'Seems like file conversion is taking longer time than expected. Please check your videos page in 1-2 minutes - your video should appear there.',
          duration: 0,
        })
        window.location.hash = ''
        closeVideoModal()
      } else if (typeof result === 'string') {
        setPdfFile(null)
        setPptxCreationResult(null)
        navigate(`/video/${result}`)
        closeVideoModal()
      }
    }
  }

  const handleSelectTemplate = (value) => {
    if (value === 'new') {
      setIsNewVideoModalOpen(false)
      navigate('/templates')
      return
    }
    if (value) {
      localStorage.setItem('pptxCreationTemplateId', value)
    } else {
      localStorage.removeItem('pptxCreationTemplateId')
    }
    setSelectedTemplate(value)
  }

  const handleStaticModeChange = (e) => setStaticMode(e.target.checked ? 'static' : null)

  const beforeUpload = (file) => {
    if (file.size / 1024 / 1024 > 30) return notification.error({ message: 'File must be smaller than 30MB' })
    if (!allowedMimeTypes.includes(file.type)) {
      return notification.error({ message: 'File should be in PDF, PPT or PPTX format.' })
    }
    setPdfFile(file)
    return false
  }

  useEffect(() => {
    const privateTemplates = templates?.filter((t) => t?.template?.private === true)
    if (privateTemplates && privateTemplates.length) {
      setPrivateTemplates(privateTemplates)
    } else {
      // hack to show link when no templates
      setPrivateTemplates([{ name: 'Create template...', _id: 'new' }])
    }
  }, [templates])

  return (
    <Scrollbars className="template-scrollbar from-pdf">
      <div className="story-card">
        <div className="text-container">
          <h2>Create video from presentation in a few clicks</h2>
          <ul>
            <li>
              For PPTX/PPT we will convert each presentation object into dynamic object on your video. Notes will be
              converted to speech text.
            </li>
            <li>For PDF each slide will be converted to video slide background as a static image</li>
          </ul>
          <Dragger
            name="file"
            accept={allowedExtensions.map((ext) => `.${ext}`).join(',')}
            multiple={false}
            fileList={pdfFile ? [pdfFile] : []}
            showUploadList={{ showRemoveIcon: false }}
            beforeUpload={beforeUpload}
            className="pdf-uploader"
          >
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload.
              <br />
              File must be smaller than 30MB
            </p>
          </Dragger>
          <div>
            <div className="select-template-label">
              Select template
              <Tooltip
                title={
                  <>
                    You can use your own template to create a video close to your expectations. Please{' '}
                    <a
                      href="https://elai.freshdesk.com/support/solutions/articles/73000639566-create-video-from-template"
                      target="_blank"
                      rel="noreferrer"
                    >
                      read here
                    </a>{' '}
                    to see how it works.
                  </>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </div>
            <div className="controls-wrapper">
              <Select
                allowClear
                placeholder={
                  <span style={{ color: '#31353B' }}>
                    <Icon name="vector" /> Template{' '}
                  </span>
                }
                defaultValue={localStorage.getItem('pptxCreationTemplateId')}
                options={privateTemplates.map((v) => ({ label: v.name, value: v._id }))}
                onChange={handleSelectTemplate}
              />
              {pdfFile && pdfFile.type !== 'application/pdf' && (
                <div>
                  <Checkbox onChange={handleStaticModeChange}>Convert Slides as Static Images</Checkbox>
                  <Tooltip title="When enabled, PPTX slides will be converted to static images, similar to PDFs. Use this option if you encounter issues with object conversion (e.g., missing elements or formatting problems). Please note that individual slide elements cannot be modified after conversion.">
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
          <Button
            type="primary"
            style={style.createButton}
            disabled={!pdfFile}
            loading={isVideoCreating}
            onClick={() => createVideoFromPresentation()}
          >
            Create video
          </Button>
        </div>
      </div>
      <PptxFontsPromptModal
        showFontPrompt={showFontPrompt}
        setShowFontPrompt={setShowFontPrompt}
        setIsVideoCreating={setIsVideoCreating}
        createVideoFromPresentation={createVideoFromPresentation}
        missedFonts={pptxCreationResult?.missedFonts}
      />
    </Scrollbars>
  )
}
