import { Card, Space, Button } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { DragSource, DropTarget } from 'react-dnd'
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import ShortcutTooltip from '../../shortcutTooltip'
import { osMap } from '../../../../../utils/constants'
import { os } from '../../../constants'

import Icon from '../../../../../components/Icon'
import { videoFormats } from '../../../../../data/formats'
import { SlideNumber } from './slideNumber'

const ItemTypes = {
  SLIDE: 'slide',
}
const slideItemWidth = 182

const styles = {
  wrapper: { position: 'relative', transform: 'translate(0px, 0px)' },
  container: { width: '180px', height: '101px' },
}

const iconCopy = <Icon name="copy" />
const iconDelete = <Icon name="delete" />

const SlideItem = forwardRef(function Slide(
  {
    index,
    slides,
    slidesLimit,
    videoFormat,
    activeSlide,
    updateActiveSlide,
    deleteSlide,
    duplicateSlide,
    setTransitionModal,
    isDragging,
    connectDragSource,
    connectDropTarget,
    player,
    canvasRegistry,
  },
  ref,
) {
  const elementRef = useRef(null)
  connectDragSource(elementRef)
  connectDropTarget(elementRef)
  const opacity = { opacity: isDragging ? 0 : 1 }
  useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current,
  }))

  const slide = slides[index]
  const isActive = activeSlide === index
  const totalSlides = slides.length
  const thumbnail = canvasRegistry.thumbnails[slide.id]

  const videoFormatWidth = useMemo(
    () => (videoFormats[videoFormat || '16_9'].width * slideItemWidth) / 640,
    [videoFormat, slideItemWidth],
  )

  const limiterWidth = useMemo(
    () => ({ width: (slideItemWidth - videoFormatWidth) / 2 }),
    [slideItemWidth, videoFormatWidth],
  )

  const handleChangeActiveSlide = (index) => {
    // Update the active slide only when:
    // 1. The player's canvas is ready (no loading in progress), or
    // 2. It's the initial state where all canvases are still loading
    if ((player?.canvasReady && !player?.canvasesLocked) || !canvasRegistry.ready) updateActiveSlide(index)
  }

  const handleUpdate = () => handleChangeActiveSlide(index)

  const handleRef = (ref) => {
    if (!ref) return

    if (thumbnail && player.status !== 'playing') {
      ref.innerHTML = ''
      thumbnail.style.width = '100%'
      ref.appendChild(thumbnail)
    } else {
      const canvas = ref.querySelector('canvas')
      if (canvas) ref.removeChild(canvas)
    }
  }

  const handleCopySlide = () => {
    duplicateSlide(index)
  }

  const handleDeleteSlide = () => {
    deleteSlide(index)
  }

  const handleSetTransition = () => {
    setTransitionModal(index)
  }

  return (
    <div
      ref={elementRef}
      className={`item-container ${isActive ? 'active' : ''} ${
        !player.canvasReady || player.canvasesLocked ? 'waiting' : ''
      }`}
      style={player.canvasReady && !player.canvasesLocked ? styles.wrapper : styles.wrapperDisabled}
    >
      <div className="item-wrapper" style={opacity}>
        <SlideNumber slides={slides} slideIndex={index} updateActiveSlide={handleChangeActiveSlide} />
        <div className="item-card-wrapper">
          <Card
            className="item"
            size="small"
            cover={
              <div className="item-thumbnail" onClick={handleUpdate}>
                <div style={styles.container} ref={handleRef}>
                  {!thumbnail && (
                    <div className="item-thumbnail-loading">
                      <LoadingOutlined />
                    </div>
                  )}
                </div>
                <div className="video-format-limiter" style={limiterWidth} />
                <div className="video-format-limiter right" style={limiterWidth}></div>
              </div>
            }
            data-slide-index={index}
          />
          <Space size={4} direction="vertical" className="item-btns-wrapper">
            {!player.activePreview && (
              <ShortcutTooltip
                title={totalSlides < slidesLimit ? 'Duplicate slide' : 'Maximum slides limit reached'}
                keyName="D"
                placement="top"
                showKeys={totalSlides < slidesLimit}
              >
                <Button
                  className="item-btn copy"
                  type="primary"
                  icon={iconCopy}
                  onClick={handleCopySlide}
                  disabled={totalSlides >= slidesLimit}
                />
              </ShortcutTooltip>
            )}
            {totalSlides > 1 && !player.activePreview && (
              <ShortcutTooltip
                title="Remove slide"
                keyName={os === osMap.MAC ? 'DEL' : 'BCKSP'}
                placement="bottom"
                showMetaKey={false}
              >
                <Button className="item-btn delete" type="default" icon={iconDelete} onClick={handleDeleteSlide} />
              </ShortcutTooltip>
            )}
          </Space>
        </div>
      </div>
      {index < totalSlides - 1 && (
        <div className={`transition ${slide.transition ? 'active' : ''}`}>
          <svg
            onClick={handleSetTransition}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5.6" cy="8.31776" r="5.1" stroke="#999999" />
            <path
              d="M10.8508 9.11532C11.0593 8.93881 11.255 8.74118 11.4347 8.52271C13.0488 6.56084 12.7668 3.66196 10.805 2.04788C8.84311 0.433799 5.94422 0.715739 4.33014 2.67761C4.18941 2.84867 4.06309 3.02684 3.95105 3.21067C3.39644 3.46484 2.88139 3.82421 2.43756 4.28572C2.62968 3.48734 3.00114 2.71902 3.55791 2.04227C5.52288 -0.346092 9.05195 -0.689323 11.4403 1.27564C13.8287 3.24061 14.1719 6.76968 12.2069 9.15805C11.601 9.89452 10.8464 10.4365 10.0223 10.7764C10.4105 10.2651 10.6856 9.70105 10.8508 9.11532Z"
              fill="#999999"
            />
          </svg>
        </div>
      )}
    </div>
  )
})

export default DropTarget(
  ItemTypes.SLIDE,
  {
    hover(props, monitor, component) {
      if (!component) {
        return null
      }
      const node = component.getNode()
      if (!node) {
        return null
      }
      const dragIndex = monitor.getItem().index
      const hoverIndex = props.index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = node.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      props.moveSlide(dragIndex, hoverIndex)
      monitor.getItem().index = hoverIndex
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    ItemTypes.SLIDE,
    {
      beginDrag: (props) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )(SlideItem),
)
