import { request } from '../api'
import { apiUrl } from '../config'
import { blankSlide, getDefaultVoice } from '../videos'
import { clone, elaiNotification as notification, uniqueId } from '../helpers'
import { maxVideoNameLength } from '../constants'

const defaultCreateRequestData = { name: 'Video Name', tags: [], slides: [] }

export const requestCreateVideo = async (data = {}) => {
  const requestData = { ...defaultCreateRequestData, ...data }
  const video = await request({ method: 'post', url: '/videos', data: requestData })
  return video?._id
}

/**
 * @param data - { sourceId } - id of a video to be copied
 * @returns {String} - id of created video
 */
export const requestDuplicateVideo = async (data = {}) => {
  const video = await request({ method: 'post', url: `/videos/copy`, data })
  return video?._id
}

/**
 * @param from {String} - html or url
 * @param type {String} - url|html|text
 * @param folderId {String} - MongoDB folder _id
 * @returns {Promise<null|number|string|string|*>}
 */
export const requestCreateStory = async ({ from, type, folderId }) => {
  const data = { folderId, from }
  const url = `/videos/text/${type}`
  const video = await request({ method: 'post', url, data })
  return video?._id
}

export const createVideoSlidesByAvatar = (avatar, voices) => {
  let slides = []
  const slide = clone(blankSlide())
  const currentAvatar = slide.canvas.objects.find((obj) => obj.type === 'avatar')
  const code = avatar.variants?.[0] ? `${avatar.code}.${avatar.variants[0].code}` : avatar.code
  const mergedAvatar = avatar.variants?.[0] ? { ...avatar.variants?.[0], ...avatar } : avatar
  const { gender, type, limit, accountId, canvas, tilt } = mergedAvatar
  currentAvatar.src = canvas
  currentAvatar.tilt = tilt
  slide.id = uniqueId()
  slide.avatar = { canvas, gender, code, type, limit, accountId }
  slide.language = 'English'
  slides.push({ ...slide, ...getDefaultVoice(voices, gender) })

  return { slides, tags: ['avatar'] }
}

export const requestCreateVideoFromThinkificCourse = async (id, name) => {
  const data = await request({
    method: 'get',
    url: `${apiUrl}/thinkific/courses/${id}`,
    allowedStatus: 404,
  })

  if (!data)
    return notification.error({
      message:
        'Seems like this course is not published. If you want to create video from "draft" course, please publish it temporary first.',
      duration: null,
    })

  let slides = []
  data.forEach((item) => {
    slides.push({ ...blankSlide(), speech: item.title, id: uniqueId() })
    if (item.content) {
      let paragraphs = [...new DOMParser().parseFromString(item.content, 'text/html').getElementsByTagName('p')]
      paragraphs.forEach((paragraph) => {
        if (paragraph.innerText) slides.push({ ...blankSlide(), speech: paragraph.innerText, id: uniqueId() })
      })
    }
  })

  return {
    name,
    slides,
  }
}

export const requestCopyVideoToAdmin = async (video, user, logout) => {
  if (user.adminAccountId) logout()
  const { slides, data, tags, type, sourceId, userEmail } = video
  const emailSuffix = ` - ${userEmail || user.email}`
  // If the video name is too long, the validation will throw an error, and the video will not be copied.
  const maxAvailableVideoNameLength = maxVideoNameLength - emailSuffix.length
  if (video.name.length > maxAvailableVideoNameLength) {
    video.name = `${video.name.substring(0, maxAvailableVideoNameLength - 1)}…`
  }
  const copiedVideo = await request({
    method: 'post',
    url: 'videos/',
    data: { name: `${video.name}${emailSuffix}`, slides, data, tags, type, sourceId },
  })
  if (copiedVideo) window.location.href = `/video/${copiedVideo._id}`
}

export const requestUploadPptx = async ({ file, folderId, urlPath, customErrorHandler, mode, templateId }) => {
  const data = new FormData()
  data.append('file', file)
  data.append('mode', mode)
  data.append('folderId', folderId)
  data.append('templateId', templateId)
  if (urlPath) data.append('urlPath', urlPath)
  return await request({
    method: 'post',
    url: 'videos/from-presentation',
    data,
    customErrorHandler,
  })
}

/**
 *
 * @param data - { language }
 * @param videoId {String} - videoId to be translated
 * @param customErrorHandler
 * @returns {Object} video object from DB
 */
export const requestTranslateVideo = async ({ data, videoId, customErrorHandler = undefined }) => {
  const video = await request({
    method: 'post',
    customErrorHandler,
    url: `videos/translate/${videoId}`,
    data,
  })
  if (!video) return
  return video
}
